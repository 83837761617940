// src/components/GoogleOneTap.js
import React, { useEffect } from "react"
import { isEmpty } from "lodash"
import { loginWithGoogleOneTabResponse } from "../services/store/api"
import { storeToken, getUser } from "../services/store/utils"
import { doRedirect } from "../components/MyAccount/utils"

const clientID = process.env.GATSBY_GOOGLE_CLIENT_ID
const hasGoogleClientId = !isEmpty(clientID)
const GoogleOneTap = () => {
    useEffect(() => {
        if (hasGoogleClientId) {
            const script = document.createElement("script")
            script.src = "https://accounts.google.com/gsi/client"
            script.async = true
            script.defer = true

            document.body.appendChild(script)

            script.onload = () => {
                setTimeout(() => {
                    try {
                        window.google.accounts.id.initialize({
                            client_id: clientID, // Replace with your actual Client ID
                            // auto_select: true,
                            callback: response => {
                                asyncCall(response.credential)
                            },
                        })

                        window.google.accounts.id.prompt()
                    } catch (error) {
                        console.log("GoogleOneTab error", error)
                    }
                }, 1000)
            }

            async function asyncCall(credential) {
                const result = await loginWithGoogleOneTabResponse({
                    token: credential,
                })
                storeToken(result.data)
                setTimeout(() => {
                    let user = getUser()
                    if (user?.email) doRedirect("/login", true)
                }, 1000)
            }

            return () => {
                document.body.removeChild(script)
            }
        }
    }, [])

    if (!hasGoogleClientId) return <></>

    return <div id="g_id_onload" data-client_id={clientID} />
}

export default GoogleOneTap
